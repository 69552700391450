<template>
  <div class="waybillRepositoryPage">
    <div class="facilityBox" v-show="!flag.riskVerifying">
      <div style="
          display: flex;
          justify-content: space-between;
          align-content: center;
        ">
        <el-breadcrumb separator="/" style="margin: 10px 0">
          <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>选择业务</el-breadcrumb-item>
          <el-breadcrumb-item>运单库</el-breadcrumb-item>
          <el-breadcrumb-item>{{ getWaybillType.rateType }}-{{ getWaybillType.taskType }}-{{
            getWaybillType.sceneType
          }}
          </el-breadcrumb-item>
        </el-breadcrumb>

        <el-tooltip placement="left">
          <div slot="content">
            1.上传运单表格仅限Excel（Office2010及以上版本，WPS2021年及以后版本）<br />
            2.点击“下载运单模板”按钮，获得Excel模板<br />
            3.模板表格中第2页有案例可参考<br />
            4.模板中红色字段为必填项<br />
            5.将运单上传至库中，点击“开启流程”将开始作业
          </div>
          <div style="margin: 0px 20px; color: #0f5fff; cursor: pointer">
            <i class="el-icon-question"></i>
            <span>帮助</span>
          </div>
        </el-tooltip>
      </div>

      <!-- 顶部按钮组 -->
      <div style="display: flex; justify-content: space-between">
        <el-form ref="searchForm" inline :model="searchForm" size="mini">
          <el-form-item label="待开票申请编号" prop="importBatchID">
            <el-input v-model="searchForm.importBatchID" placeholder="请输入待开票申请编号"></el-input>
          </el-form-item>
          <el-button type="primary" size="mini" icon="el-icon-zoom-in" @click="search">搜索</el-button>
          <el-button type="primary" size="mini" icon="el-icon-delete" @click="$refs['searchForm'].resetFields()">清除
          </el-button>
          <el-button type="primary" size="mini" icon="el-icon-download" @click="downTemplate(1)">风控标准说明</el-button>
          <el-button type="primary" size="mini" icon="el-icon-box" @click="getMergeList"
            :disabled="!tableList1.length">合并运单</el-button>
        </el-form>
        <div style="padding-right: 8px">
          <input type="file" style="display: none" @change="handleUploadWaybill" ref="waybill" />
          <input type="file" style="display: none" @change="handleAddToUpload" ref="addTo" />
          <input type="file" style="display: none" @change="importTrace" ref="importTrace" />
          <input type="file" style="display: none" @change="importEvidence" ref="importEvidence" />
          <el-button size="mini" @click="$router.push('/waybill/waybillManage/businessChoose')"
            icon="el-icon-arrow-left">返回</el-button>
          <el-button type="primary" size="mini" icon="el-icon-upload" @click="$refs.importEvidence.click()" v-if="flag.importTraceAuth &&
            $entrustSceneIds.includes(Number(getWaybillType.sceneId))
            ">导入凭证
          </el-button>
          <el-button type="primary" size="mini" icon="el-icon-upload" @click="$refs.importTrace.click()"
            v-if="flag.importTraceAuth">导入轨迹
          </el-button>
          <el-button type="primary" size="mini" @click="$refs.waybill.click()" :loading="pagination1.loading"
            icon="el-icon-upload">上传运单表格（单次最大可上传{{ maxNum }}条）</el-button>
          <el-button type="primary" size="mini" icon="el-icon-download" @click="downTemplate(2)">下载运单模板</el-button>
        </div>
      </div>
      <!-- 运单库表格 -->
      <el-table :data="tableList1" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" height="670"
        max-height="670">
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="ImportBatchID" label="待开票申请编号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="备注" width="200">
          <template slot-scope="scope">
            <div :style="{ color: scope.row.Remark ? '#909399' : '#66b1ff' }" style="
                cursor: pointer;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              " @click="openUpdateReamrk(scope.row)">
              {{ scope.row.Remark ? scope.row.Remark : "暂无备注" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="总计运单数量" width="200">
          <template slot-scope="scope">
            <div style="cursor: pointer; display: inline-block">
              <el-tag @click="openAllWaybill(scope.row)">{{
                scope.row.WayBillCount
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="正确运单数量" width="200">
          <template slot-scope="scope">
            <div style="cursor: pointer; display: inline-block">
              <el-tag type="success" @click="openRightWaybill(scope.row)">{{
                scope.row.CorrectBillCount
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="错误运单数量" width="200">
          <template slot-scope="scope">
            <div style="cursor: pointer; display: inline-block">
              <el-tag type="danger" @click="openErrorWaybill(scope.row)">{{
                scope.row.ErrorBillCount
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="" label="审核中运单数量" width="200">
          <template slot-scope="scope">
            <div style="cursor: pointer; display: inline-block">
              <el-tag type="info" @click="openAutioInfo(scope.row)">{{
                scope.row.AuditBillCount
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" :label="getWaybillType.sceneId == 7 ? '总工单费' : '总运费'"
          width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CorrectAmount" :label="getWaybillType.sceneId == 7 ? '正确运单工单费' : '正确运单运费'
          " width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CorrectAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ImportTime" label="上传/撤回时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="UpdateTime" label="更新时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" prop="" label="操作" width="530">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" :loading="scope.row.addToing" @click="handleAddToClick(scope.row)">续传
            </el-button>
            <el-button type="primary" size="mini" @click="submitRiskVerify(scope.row, 'all')">提交风控</el-button>
            <!-- <el-button type="primary" size="mini" @click="openErrorWaybill(scope.row)">风控结果</el-button> -->
            <el-button type="primary" size="mini" :loading="scope.row.openFlowing" @click="openFlow(scope.row)">开启流程
            </el-button>
            <el-button type="primary" size="mini" @click="openVoucherDialog(scope.row, 'ImportBatchID')">凭证</el-button>
            <el-button type="danger" size="mini" @click="deleteOne(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 运单库表格分页 -->
      <el-pagination background style="margin-top: 16px" @current-change="pagination1Change"
        :current-page.sync="pagination1.page" :page-size="pagination1.pagesize" @size-change="pagination1SizeChange"
        :page-sizes="[10, 20, 30, 50, 100]" layout="total,sizes, prev, pager, next, jumper" :total="pagination1.total">
      </el-pagination>
    </div>

    <!-- 风控校验 -->
    <div class="riskVerify" v-if="flag.riskVerifying">
      <el-progress :width="400" type="circle" :stroke-width="20" :percentage="flag.riskProgress"></el-progress>
      <p class="desc">风控校验中，请稍后...</p>
    </div>

    <!-- 合并运单dialog -->
    <el-dialog width="1300px" :visible.sync="flag.mergeTableDialog" append-to-body>
      <el-button type="primary" size="mini" icon="el-icon-box" @click="mergeTable" :disabled="!multipleSelection.length"
        style="margin-bottom:10px;">合并运单</el-button>
      <el-table :data="mergeTableList" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        @selection-change="handleSelectionChange" style="width: 100%" height="550">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip width="100"></el-table-column>
        <el-table-column align="center" prop="ImportBatchID" label="待开票申请编号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="备注"></el-table-column>
        <el-table-column align="center" prop="" label="总计运单数量" width="200">
          <template slot-scope="scope">
            <div style="cursor: pointer; display: inline-block">
              <el-tag>{{
                scope.row.WayBillCount
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background style="margin-top: 16px" @current-change="mergePaginationChange"
        :current-page.sync="mergePagination.page" :page-size="mergePagination.pagesize"
        @size-change="mergePaginationSizeChange" :page-sizes="[10, 50, 100, 500, 1000]"
        layout="total,sizes, prev, pager, next, jumper" :total="mergePagination.total">
      </el-pagination>
    </el-dialog>

    <!-- 所有运单dialog -->
    <el-dialog width="1300px" :visible.sync="flag.allWaybillDialog" append-to-body
      @close="$refs['searchDriverForm'].resetFields()">
      <div style="display: flex; align-items: center">
        <el-form ref="searchDriverForm" inline :model="searchDriverForm" size="mini">
          <el-form-item label="司机姓名" prop="driverName">
            <el-input v-model="searchDriverForm.driverName" placeholder="请输入司机姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="driverPhone">
            <el-input v-model="searchDriverForm.driverPhone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="身份证号" prop="driverIdCard">
            <el-input v-model="searchDriverForm.driverIdCard" placeholder="请输入身份证号"></el-input>
          </el-form-item>
          <el-form-item label="运单号" prop="WaybillID">
            <el-input v-model="searchDriverForm.WaybillID" placeholder="请输入运单号"></el-input>
          </el-form-item>
          <el-form-item label="客户单号" prop="CarriageBillID" v-if="getWaybillType.sceneId != 7">
            <el-input v-model="searchDriverForm.CarriageBillID" placeholder="请输入客户单号"></el-input>
          </el-form-item>
          <el-button type="primary" size="mini" icon="el-icon-zoom-in" @click="openAllWaybill(currentItem)">搜索
          </el-button>
          <el-button type="primary" size="mini" icon="el-icon-delete" @click="$refs['searchDriverForm'].resetFields()">
            清除
          </el-button>
          <el-button style="margin-bottom: 16px" icon="el-icon-download" size="mini" type="primary"
            @click="downloadWaybill(null)">下载运单
          </el-button>
          <el-button style="margin-bottom: 16px" icon="el-icon-delete" size="mini" type="danger"
            @click="deleteAllWaybill">删除勾选运单</el-button>
          <el-button style="margin-bottom: 16px" size="mini" type="primary"
            @click="createNewOrder(currentItem, 'all', 'total')">全部生成新单</el-button>
          <el-button style="margin-bottom: 16px" size="mini" type="primary"
            @click="createNewOrder(currentItem, 'some', 'total')">勾选生成新单</el-button>
        </el-form>
        <!-- 下载按钮 -->
      </div>
      <!-- 表格信息 -->
      <el-table style="width: 100%" height="500" :data="currentAllWaybillList"
        v-loading="pagination2.loading || flag.dialogLoading" @select="currentAllWaybillSelectedChange"
        @select-all="currentAllWaybillSelectedChange" ref="allWaybillRef"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-if="getWaybillType.sceneId == 7">
        <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskScenceTypeName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="操作员姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverPhone" label="操作员手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="操作员身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.DriverUserID, scope.row.DriverIDCard)
              ">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.PayeeUserID, scope.row.PayeeIDCard)
              ">
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.LeaderUserID, scope.row.LeaderIDCard)
              ">
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" label="工程设备名称" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverAllotCount" label="操作员装货数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="操作员运输单价（人民币）" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="工单费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeeAmount" label="工单总金额" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.PayeeAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Rrmark" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column
          align="center"
          prop=""
          label="开票金额"
          width="200"
          show-overflow-tooltip
        ></el-table-column> -->
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" prop="" label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openVoucherDialog(scope.row, 'WaybillID')">凭证</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table style="width: 100%" height="500" :data="currentAllWaybillList"
        v-loading="pagination2.loading || flag.dialogLoading" @select="currentAllWaybillSelectedChange"
        @select-all="currentAllWaybillSelectedChange" ref="allWaybillRef"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-else>
        <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.DriverUserID, scope.row.DriverIDCard)
              ">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.PayeeUserID, scope.row.PayeeIDCard)
              ">
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.LeaderUserID, scope.row.LeaderIDCard)
              ">
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LicensePlate" label="车牌" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
              {{ scope.row.LicensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Start_addrees" label="起始地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.Start_addrees }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Arrivals" label="到达地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.Arrivals }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Driver_transportation_price | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount_count" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.amount_count | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Taxes" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Taxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedTaxes" label="委托代开服务费/税费" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedTaxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedTotalAmount" label="委托代开运单总金额" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column
          align="center"
          prop=""
          label="开票金额"
          width="200"
          show-overflow-tooltip
        ></el-table-column> -->
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" prop="" label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openVoucherDialog(scope.row, 'WaybillID')">凭证</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination background style="margin-top: 16px" @current-change="pagination2Change"
        :current-page.sync="pagination2.page" :page-size="pagination2.pagesize" :page-sizes="[10, 20, 30, 40, 50, 100]"
        @size-change="pagination2SizeChange" layout="total, sizes,prev, pager, next, jumper" :total="pagination2.total">
      </el-pagination>
    </el-dialog>

    <!-- 正确运单dialog -->
    <el-dialog width="1300px" :visible.sync="flag.rightWaybillDialog" append-to-body
      @close="$refs['searchRightForm'].resetFields()">
      <!-- 温馨提示 -->
      <el-alert style="margin-bottom: 8px" type="warning" :closable="false">
        <span slot="title">
          <span>温馨提示：</span><br />
          <span>风控时间：{{ riskTime }}</span><br />
          <span>风控结果：正确{{
            rightWaybillList.length
          }}条，已加入正确运单中</span><br />
          <span>勾选/全选所需运单，点击“开启流程”，则对这批运单开启作业</span><br />
        </span>
      </el-alert>
      <div style="display: flex; align-items: center">
        <el-form ref="searchRightForm" inline :model="searchRightForm" size="mini">
          <el-form-item label="运单号" prop="WaybillID">
            <el-input v-model="searchRightForm.WaybillID" placeholder="请输入运单号"></el-input>
          </el-form-item>
          <el-form-item label="客户单号" prop="CarriageBillID" v-if="getWaybillType.sceneId != 7">
            <el-input v-model="searchRightForm.CarriageBillID" placeholder="请输入客户单号"></el-input>
          </el-form-item>
          <el-button type="primary" size="mini" icon="el-icon-zoom-in" @click="openRightWaybill(currentItem)">搜索
          </el-button>
          <el-button type="primary" size="mini" icon="el-icon-delete" @click="$refs['searchRightForm'].resetFields()">
            清除
          </el-button>
          <el-button type="primary" size="mini" @click="downloadWaybill(1)">下载所有正确运单</el-button>
          <el-button type="primary" size="mini" :loading="currentItem.openFlowing"
            @click="chooseWaybillOpenFlow(currentItem)">开启流程
          </el-button>
          <el-button style="margin-bottom: 16px" size="mini" type="primary"
            @click="createNewOrder(currentItem, 'all', 'right')">全部生成新单</el-button>
          <el-button style="margin-bottom: 16px" size="mini" type="primary"
            @click="createNewOrder(currentItem, 'some', 'right')">勾选生成新单</el-button>
        </el-form>
        <!-- 下载按钮 -->
      </div>
      <!-- 表格信息 -->
      <el-table style="width: 100%" height="400" :data="currentRightWaybillList"
        v-loading="pagination3.loading || flag.dialogLoading" :header-cell-class-name="cellClass"
        @select="currentRightWaybillSelectedChange" @select-all="currentRightWaybillSelectedChange" ref="rightWaybillRef"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-if="getWaybillType.sceneId == 7">
        <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTypeName" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskTypeName" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskScenceTypeName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="操作员姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverPhone" label="操作员手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="操作员身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.DriverUserID, scope.row.DriverIDCard)
              ">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.PayeeUserID, scope.row.PayeeIDCard)
              ">
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.LeaderUserID, scope.row.LeaderIDCard)
              ">
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" label="工程设备名称" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverAllotCount" label="操作员装货数量" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="操作员运输单价（人民币）" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="工单费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeeAmount" label="工单总金额" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.PayeeAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Rrmark" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column
          align="center"
          prop=""
          label="开票金额"
          width="200"
          show-overflow-tooltip
        ></el-table-column> -->
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" prop="" label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openVoucherDialog(scope.row, 'WaybillID')">凭证</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-table style="width: 100%" height="400" :data="currentRightWaybillList"
        v-loading="pagination3.loading || flag.dialogLoading" :header-cell-class-name="cellClass"
        @select="currentRightWaybillSelectedChange" @select-all="currentRightWaybillSelectedChange" ref="rightWaybillRef"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-else>
        <el-table-column type="selection" width="55" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.DriverUserID, scope.row.DriverIDCard)
              ">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.PayeeUserID, scope.row.PayeeIDCard)
              ">
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="
              openDerverInfo(scope.row, scope.row.LeaderUserID, scope.row.LeaderIDCard)
              ">
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LicensePlate" label="车牌" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
              {{ scope.row.LicensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Start_addrees" label="起始地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.Start_addrees }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Arrivals" label="到达地" width="200">
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.Arrivals }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Driver_transportation_price | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount_count" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.amount_count | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Taxes" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Taxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedTaxes" label="委托代开服务费/税费" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedTaxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedTotalAmount" label="委托代开运单总金额" width="200"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rrmark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="" label="支付方式" width="200" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column
          align="center"
          prop=""
          label="开票金额"
          width="200"
          show-overflow-tooltip
        ></el-table-column> -->
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column fixed="right" align="center" prop="" label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openVoucherDialog(scope.row, 'WaybillID')">凭证</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination background style="margin-top: 16px" @current-change="pagination3Change"
        :current-page.sync="pagination3.page" :page-size="pagination3.pagesize" :page-sizes="[10, 20, 30, 40, 50, 100]"
        @size-change="pagination3SizeChange" layout="total, sizes,prev, pager, next, jumper" :total="pagination3.total">
      </el-pagination>
      <!-- 当前组合 -->
      <div style="margin: 16px 0 0 25vw" v-if="getWaybillType.sceneId == 7">
        <span style="display: inline-block; width: 120px">工单费：{{ currentRightWaybillAmount }}</span>
        <span style="display: inline-block; width: 120px">运单数量：{{ currentRightWaybillSelectedCount }}</span>
      </div>
      <div style="margin: 16px 0 0 25vw" v-else>
        <span style="display: inline-block; width: 120px">运费：{{ currentRightWaybillAmount }}</span>
        <span style="display: inline-block; width: 120px">运单数量：{{ currentRightWaybillSelectedCount }}</span>
      </div>
    </el-dialog>

    <!-- 错误运单dialog -->
    <div v-if="flag.errorWaybillDialog">
      <el-dialog width="1300px" :visible.sync="flag.errorWaybillDialog" append-to-body @close="() => {
        (pagination4.pagesize = 10), clearErrorDialog();
      }
        " class="err-dialog">
        <!-- 温馨提示 -->
        <el-alert style="margin-bottom: 8px" type="warning" :closable="false">
          <span slot="title">
            <span>温馨提示：</span><br />
            <span>风控时间：{{ riskTime }}</span><br />
            <span>风控结果：错误{{
              pagination4.total
            }}条，全部在下列错误运单表中，请修正</span><br />
          </span>
        </el-alert>
        <div style="display: flex; justify-content: space-between">
          <div>
            <el-button style="margin-bottom: 16px" size="mini" type="primary"
              @click="downloadWaybill(2)">下载错误运单</el-button>
            <el-button style="margin-bottom: 16px" size="mini" type="primary"
              @click="openEditWaybillDialog">在线编辑</el-button>
            <el-button style="margin-bottom: 16px" size="mini" type="danger"
              @click="deleteOne(currentItem, 2)">删除全部错误运单</el-button>
            <el-button style="margin-bottom: 16px" size="mini" type="danger"
              @click="deleteErrorWaybill">删除勾选运单</el-button>
            <el-button style="margin-bottom: 16px" size="mini" type="primary"
              @click="submitRiskVerify(currentItem, 'all')">全部提交风控</el-button>
            <el-button style="margin-bottom: 16px" size="mini" type="primary"
              @click="submitRiskVerify(currentItem, 'some')">勾选提交风控</el-button>

            <div>
              <el-button style="margin-bottom: 16px" size="mini" type="primary"
                @click="createNewOrder(currentItem, 'all', 'error')">全部生成新单</el-button>
              <el-button style="margin-bottom: 16px" size="mini" type="primary"
                @click="createNewOrder(currentItem, 'some', 'error')">勾选生成新单</el-button>
              <!-- <el-button style="margin-bottom: 16px" size="mini" type="primary"
                @click="Set30wErrorStatusAsync">过滤开票额度</el-button> -->
            </div>
          </div>
          <div>
            <span>人工审核：</span>
            <el-select v-model="supplierSelected" placeholder="请选择服务商" size="mini">
              <el-option v-for="item in supplierList" :key="item.value" :label="item.label" :value="item.value"
                :disabled="item.Flag == 1 || item.Disabled">
              </el-option>
            </el-select>
            <el-button style="margin-left: 10px" size="mini" type="primary" @click="submitManualReview">提交</el-button>
          </div>
        </div>
        <el-form :model="searchErrorForm" ref="searchErrorForm" label-width="106px" size="mini" @submit.native.prevent
          inline>
          <el-form-item label="错误类型(模糊)" prop="ErrorDesc">
            <el-input v-model="searchErrorForm.ErrorDesc" placeholder="请输入错误类型"></el-input>
          </el-form-item>
          <el-form-item label="错误类型(精确)" prop="ErrorDescPre">
            <el-input v-model="searchErrorForm.ErrorDescPre" placeholder="请输入错误类型"></el-input>
          </el-form-item>
          <el-form-item label="运单号" prop="WaybillID">
            <el-input v-model="searchErrorForm.WaybillID" placeholder="请输入运单号"></el-input>
          </el-form-item>
          <el-form-item label="客户单号" prop="CarriageBillID" v-if="getWaybillType.sceneId != 7">
            <el-input v-model="searchErrorForm.CarriageBillID" placeholder="请输入客户单号"></el-input>
          </el-form-item>
          <el-form-item label-width="10px">
            <el-button type="primary" size="mini" @click="openErrorWaybill()" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" size="mini" icon="el-icon-delete" @click="clearErrorDialog">
              清空
            </el-button>
          </el-form-item>
        </el-form>
        <!-- 表格信息 -->
        <ux-grid style="width: 100%" height="400" :data="currentErrorWaybillList"
          v-loading="pagination4.loading || flag.dialogLoading" :header-cell-class-name="cellClass"
          @select="currentErrorWaybillSelectedChange" @select-all="currentErrorWaybillSelectedChange"
          ref="errorWaybillRef" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          v-if="getWaybillType.sceneId == 7">
          <ux-table-column type="checkbox" align="center" width="55" fixed="left" show-overflow></ux-table-column>
          <ux-table-column align="center" field="ErrorMsg" title="错误信息" width="200" fixed="left"
            show-overflow></ux-table-column>
          <ux-table-column align="center" field="WaybillID" title="运单号" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="InvoiceTypeName" title="发票类型" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="TaskTypeName" title="业务类型" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="TaskScenceTypeName" title="场景类型" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="GoodsOwnerName" title="货主单位" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="DriverName" title="操作员姓名" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="DriverPhone" title="操作员手机号码" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="DriverIDCard" title="操作员身份证号码" width="200" show-overflow>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="
                openDerverInfo(scope.row, scope.row.DriverUserID, scope.row.DriverIDCard)
                ">
                {{ scope.row.DriverIDCard }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="PayeeName" title="收款人姓名" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="PayeePhone" title="收款人手机号码" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="PayeeIDCard" title="收款人身份证号码" width="200" show-overflow>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="
                openDerverInfo(scope.row, scope.row.PayeeUserID, scope.row.PayeeIDCard)
                ">
                {{ scope.row.PayeeIDCard }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="LeaderName" title="车队长姓名" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="LeaderPhone" title="车队长手机号码" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="LeaderIDCard" title="车队长身份证号码" width="200" show-overflow>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="
                openDerverInfo(scope.row, scope.row.LeaderUserID, scope.row.LeaderIDCard)
                ">
                {{ scope.row.LeaderIDCard }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="CarNumber" title="工程设备名称" width="200">
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
                {{ scope.row.CarNumber }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="GoodsName" title="货品名称" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="GoodsUnit" title="货品单位" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="LoadingDatetime" title="装货时间" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="SignDatetime" title="签收时间" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="OriginAddress" title="起始地" width="200">
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                {{ scope.row.OriginAddress }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="ArriveAddress" title="到达地" width="200">
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                {{ scope.row.ArriveAddress }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="DriverAllotCount" title="操作员装货数量" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="DriverCarryPrice" title="操作员运输单价（人民币）" width="200">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.DriverCarryPrice | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="CarriageTotalPrice" title="工单费" width="200">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.CarriageTotalPrice | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="ExpensesOfTaxation" title="服务费/税费" width="200">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.ExpensesOfTaxation | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="PayeeAmount" title="工单总金额" width="200">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.PayeeAmount | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="Rrmark" title="风控路径" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Rrmark1" title="处理/驳回原因" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="PayModeName" title="支付方式" width="200" show-overflow>
          </ux-table-column>
          <!-- <el-table-column
          align="center"
          prop="InvoiceAmoutTypeName"
          label="开票金额"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column> -->
          <ux-table-column align="center" field="SourceBillId" title="溯源运单号" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
          </ux-table-column>
          <ux-table-column fixed="right" align="center" field="" title="操作" width="130">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="openVoucherDialog(scope.row, 'WaybillID')">凭证</el-button>
            </template>
          </ux-table-column>
        </ux-grid>
        <ux-grid style="width: 100%" height="400" :data="currentErrorWaybillList"
          v-loading="pagination4.loading || flag.dialogLoading" :header-cell-class-name="cellClass"
          @select="currentErrorWaybillSelectedChange" @select-all="currentErrorWaybillSelectedChange"
          ref="errorWaybillRef" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-else>
          <ux-table-column type="checkbox" align="center" width="55" fixed="left" show-overflow></ux-table-column>
          <ux-table-column align="center" field="ErrorMsg" title="错误信息" width="200" fixed="left" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="WaybillNumber" title="运单号" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="CarriageBillID" title="客户单号" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="InvoiceType" title="发票类型" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="TaskType" title="业务类型" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="TaskSceneName" title="场景类型" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Shipper_name" title="货主单位" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="DriverName" title="司机姓名" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Phone" title="司机手机号码" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="DriverIDCard" title="司机身份证号码" width="200">
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="
                openDerverInfo(scope.row, scope.row.DriverUserID, scope.row.DriverIDCard)
                ">
                {{ scope.row.DriverIDCard }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="PayName" title="收款人姓名" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Recy_payment_idcard" title="收款人手机号码" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="PayeeIDCard" title="收款人身份证号码" width="200" show-overflow>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="
                openDerverInfo(scope.row, scope.row.PayeeUserID, scope.row.PayeeIDCard)
                ">
                {{ scope.row.PayeeIDCard }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="LeaderName" title="车队长姓名" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="LeaderPhone" title="车队长手机号码" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="LeaderIDCard" title="车队长身份证号码" width="200" show-overflow>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="
                openDerverInfo(scope.row, scope.row.LeaderUserID, scope.row.LeaderIDCard)
                ">
                {{ scope.row.LeaderIDCard }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="LicensePlate" title="车牌" width="200" show-overflow>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="openVehicleInfo(scope.row)">
                {{ scope.row.LicensePlate }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="Net_weight" title="装货净重(吨)" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Product_name" title="货品名称" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Product_unit" title="货品单位" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Loading_time" title="装货时间" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Submission_time" title="签收时间" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Start_addrees" title="起始地" width="200">
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                {{ scope.row.Start_addrees }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="Arrivals" title="到达地" width="200">
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                {{ scope.row.Arrivals }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="Number_allocated_drivers" title="司机装货数量" width="200"
            show-overflow></ux-table-column>
          <ux-table-column align="center" field="Driver_transportation_price" title="司机运输单价（人民币）" width="200">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.Driver_transportation_price | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="amount_count" title="运费" width="200">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.amount_count | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="Taxes" title="服务费/税费" width="200">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.Taxes | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="TotalAmount" title="运单总金额" width="200">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.TotalAmount | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="Entrusted_Collection_Amount" title="委托代开运费" width="200"
            v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.Entrusted_Collection_Amount | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="EntrustedTaxes" title="委托代开服务费/税费" width="200"
            v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.EntrustedTaxes | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="EntrustedTotalAmount" title="委托代开运单总金额" width="200"
            v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
            <template slot-scope="scope">
              <div style="color: #ff8b17">
                {{ scope.row.EntrustedTotalAmount | formatMoney }}
              </div>
            </template>
          </ux-table-column>
          <ux-table-column align="center" field="Unloading_net_weight" title="卸货净重(吨)" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="RiskPath" title="风控路径" width="215" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="Rrmark1" title="处理/驳回原因" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="PayModeName" title="支付方式" width="200" show-overflow>
          </ux-table-column>
          <!-- <el-table-column
          align="center"
          prop="InvoiceAmoutTypeName"
          label="开票金额"
          width="200"
          show-overflow-tooltip
        >
        </el-table-column> -->
          <ux-table-column align="center" field="SourceBillId" title="溯源运单号" width="200" show-overflow>
          </ux-table-column>
          <ux-table-column align="center" field="PayeeRemark" title="收款人备注" width="200" show-overflow>
          </ux-table-column>

          <ux-table-column fixed="right" align="center" field="" title="操作" width="130">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="openVoucherDialog(scope.row, 'WaybillID')">凭证</el-button>
            </template>
          </ux-table-column>
        </ux-grid>
        <!-- 分页 -->
        <div style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          " v-if="flag.errorWaybillDialog">
          <el-pagination background style="margin-top: 16px" @current-change="pagination4Change"
            :current-page.sync="pagination4.page" :page-size="pagination4.pagesize"
            :page-sizes="[10, 50, 100, 500, 1000, 2000]" @size-change="pagination4SizeChange"
            layout="total,sizes, prev, pager, next, jumper" :total="pagination4.total">
          </el-pagination>
          <el-button type="primary" size="mini" icon="el-icon-download" @click="downTemplate(1)">风控标准说明</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 审核中运单dialog -->
    <el-dialog width="600px" :visible.sync="flag.auditWaybillDialog" append-to-body>
      <div style="display: flex; align-items: center; flex-direction: column">
        <div style="
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 32px;
          ">
          <strong style="margin-bottom: 16px; font-size: 18px">温馨提示</strong>
          <p style="font-size: 15px">提交时间：{{ auditInfo.CommitTime }}</p>
          <p style="font-size: 15px">提交数量：{{ auditInfo.CommitNum }}</p>
        </div>
        <div style="
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 32px;
          ">
          <strong style="margin-bottom: 16px; font-size: 18px">人工审核结果</strong>
          <p style="font-size: 15px">
            正确<span style="font-weight: 600; color: #67c23a">{{
              auditInfo.CorrectNum
            }}</span>条，已在正确运单中
          </p>
          <p style="font-size: 15px">
            错误<span style="font-weight: 600; color: #f56c6c">{{
              auditInfo.ErrorNum
            }}</span>条，已在错误运单中
          </p>
          <p style="font-size: 15px">
            审核中<span style="font-weight: 600; color: #e6a23c">{{
              auditInfo.AuditingNum
            }}</span>条，请继续等待结果
          </p>
          <p style="font-size: 15px">
            自动审核中<span style="font-weight: 600; color: #e6a23c">{{
              auditInfo.AutoNum
            }}</span>条，请继续等待结果
          </p>
          <p style="margin-top: 30px; font-weight: bold">
            审核服务商：{{ auditInfo.ProviderName }}
          </p>
        </div>
      </div>
    </el-dialog>

    <!-- 在线编辑dialog -->
    <el-dialog width="1300px" :visible.sync="flag.editWaybillDialog" append-to-body
      @close="$refs['searchErrorEditForm'].resetFields()">
      <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;
        ">
        <strong style="font-size: 16px">在线编辑中</strong>
      </div>
      <el-form :model="searchErrorEditForm" ref="searchErrorEditForm" label-width="100px" size="mini"
        @submit.native.prevent inline>
        <el-form-item label="错误类型" prop="ErrorDesc">
          <el-input v-model="searchErrorEditForm.ErrorDesc" placeholder="请输入错误类型"></el-input>
        </el-form-item>
        <el-form-item label="运单号" prop="WaybillID">
          <el-input v-model="searchErrorEditForm.WaybillID" placeholder="请输入运单号"></el-input>
        </el-form-item>
        <el-form-item label="客户单号" prop="CarriageBillID" v-if="getWaybillType.sceneId != 7">
          <el-input v-model="searchErrorEditForm.CarriageBillID" placeholder="请输入客户单号"></el-input>
        </el-form-item>
        <el-form-item :label="getWaybillType.sceneId == 7 ? '操作员手机号' : '司机手机号'" prop="driverPhone">
          <el-input v-model="searchErrorEditForm.driverPhone" :placeholder="getWaybillType.sceneId == 7
            ? '请输入操作员手机号'
            : '请输入司机手机号'
            "></el-input>
        </el-form-item>
        <el-form-item label="收款人手机号" prop="payeePhone">
          <el-input v-model="searchErrorEditForm.payeePhone" placeholder="请输入收款人手机号"></el-input>
        </el-form-item>
        <el-form-item label="起始地" prop="originAddress">
          <el-input v-model="searchErrorEditForm.originAddress" placeholder="请输入起始地"></el-input>
        </el-form-item>
        <el-form-item label="到达地" prop="arriveAddress">
          <el-input v-model="searchErrorEditForm.arriveAddress" placeholder="请输入到达地"></el-input>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="mini" @click="openEditWaybillDialog('search')"
            icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="mini" icon="el-icon-delete" @click="$refs['searchErrorEditForm'].resetFields()">
            清空
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 表格信息 -->
      <vxe-table ref="editWaybillRef" v-loading="flag.dialogLoading" keep-source
        @checkbox-change="currentEditWaybillSelectedChange" @checkbox-all="currentEditWaybillSelectedChange"
        :header-cell-class-name="headerCellClassName" :cell-class-name="cellClassName" border resizable show-overflow
        :data="currentEditWaybillList" :edit-config="{ trigger: 'click', mode: 'cell' }"
        v-if="getWaybillType.sceneId == 7">
        <vxe-table-column align="center" field="ErrorMsg" title="错误信息" width="200" fixed="left"></vxe-table-column>
        <vxe-table-column align="center" field="WaybillID" title="运单号" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="InvoiceTypeName" title="发票类型" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="TaskTypeName" title="业务类型" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="TaskScenceTypeName" title="场景类型" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="GoodsOwnerName" title="货主单位" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="DriverName" title="操作员姓名" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="DriverPhone" title="操作员手机号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="DriverIDCard" title="操作员身份证号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <!-- <template #default="{ row }">
            <div @click="openDerverInfo(row.DriverUserID)">
              <span>{{ row.DriverIDCard }}</span>
            </div>
          </template> -->
        </vxe-table-column>
        <vxe-table-column align="center" field="PayeeName" title="收款人姓名" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="PayeePhone" title="收款人手机号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="PayeeIDCard" title="收款人身份证号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <!-- <template #default="{ row }">
            <div @click="openDerverInfo(row.PayeeUserID)">
              <span>{{ row.PayeeIDCard }}</span>
            </div>
          </template> -->
        </vxe-table-column>
        <vxe-table-column align="center" field="LeaderName" title="车队长姓名" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="LeaderPhone" title="车队长手机号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="LeaderIDCard" title="车队长身份证号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <!-- <template #default="{ row }">
            <div @click="openDerverInfo(row.LeaderUserID)">
              <span>{{ row.LeaderIDCard }}</span>
            </div>
          </template> -->
        </vxe-table-column>
        <vxe-table-column align="center" field="CarNumber" title="工程设备名称" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.CarNumber }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="GoodsName" title="货品名称" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="GoodsUnit" title="货品单位" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="LoadingDatetime" title="装货时间" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="SignDatetime" title="签收时间" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="OriginAddress" title="起始地" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.OriginAddress }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="ArriveAddress" title="到达地" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.ArriveAddress }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="DriverAllotCount" title="操作员装货数量" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="DriverCarryPrice" title="操作员运输单价（人民币）" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.DriverCarryPrice | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="CarriageTotalPrice" title="工单费" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.CarriageTotalPrice | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="ExpensesOfTaxation" title="服务费/税费" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.ExpensesOfTaxation | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="PayeeAmount" title="工单总金额" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.PayeeAmount | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <!-- <vxe-table-column align="center" field="Rrmark" title="风控路径" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column> -->
        <vxe-table-column align="center" field="Rrmark" title="风控路径" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="Rrmark1" title="处理/驳回原因" width="200"></vxe-table-column>
        <!-- <vxe-table-column align="center" field="Rrmark1" title="处理/驳回原因" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column> -->
        <!-- <vxe-table-column align="center" field="" title="支付方式" width="200" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="" title="开票金额" width="200" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column> -->
        <vxe-table-column align="center" field="SourceBillId" title="溯源运单号" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
      </vxe-table>
      <!-- 表格信息 -->
      <vxe-table ref="editWaybillRef" v-loading="flag.dialogLoading" keep-source
        @checkbox-change="currentEditWaybillSelectedChange" @checkbox-all="currentEditWaybillSelectedChange"
        :header-cell-class-name="headerCellClassName" :cell-class-name="cellClassName" border resizable show-overflow
        :data="currentEditWaybillList" :edit-config="{ trigger: 'click', mode: 'cell' }" v-else>
        <vxe-table-column align="center" field="ErrorMsg" title="错误信息" width="200" fixed="left"></vxe-table-column>
        <vxe-table-column align="center" field="WaybillNumber" title="运单号" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="CarriageBillID" title="客户单号" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="InvoiceType" title="发票类型" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="TaskType" title="业务类型" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="TaskSceneName" title="场景类型" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="Shipper_name" title="货主单位" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="DriverName" title="司机姓名" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="Phone" title="司机手机号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="DriverIDCard" title="司机身份证号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <!-- <template #default="{ row }">
            <div @click="openDerverInfo(row.DriverUserID)">
              <span>{{ row.DriverIDCard }}</span>
            </div>
          </template> -->
        </vxe-table-column>
        <vxe-table-column align="center" field="PayName" title="收款人姓名" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="Recy_payment_idcard" title="收款人手机号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="PayeeIDCard" title="收款人身份证号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <!-- <template #default="{ row }">
            <div @click="openDerverInfo(row.PayeeUserID)">
              <span>{{ row.PayeeIDCard }}</span>
            </div>
          </template> -->
        </vxe-table-column>
        <vxe-table-column align="center" field="LeaderName" title="车队长姓名" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="LeaderPhone" title="车队长手机号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="LeaderIDCard" title="车队长身份证号码" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <!-- <template #default="{ row }">
            <div @click="openDerverInfo(row.LeaderUserID)">
              <span>{{ row.LeaderIDCard }}</span>
            </div>
          </template> -->
        </vxe-table-column>
        <vxe-table-column align="center" field="LicensePlate" title="车牌" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.LicensePlate }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="Net_weight" title="装货净重(吨)" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="Product_name" title="货品名称" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="Product_unit" title="货品单位" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="Loading_time" title="装货时间" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="Submission_time" title="签收时间" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="Start_addrees" title="起始地" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.Start_addrees }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="Arrivals" title="到达地" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.Arrivals }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="Number_allocated_drivers" title="司机装货数量" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="Driver_transportation_price" title="司机运输单价（人民币）" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.Driver_transportation_price | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="amount_count" title="运费" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.amount_count | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="Taxes" title="服务费/税费" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.Taxes | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="TotalAmount" title="运单总金额" width="200"
          :edit-render="{ name: 'input', autoselect: true }">
          <template #default="{ row }">
            <span>{{ row.TotalAmount | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="Entrusted_Collection_Amount" title="委托代开运费" width="200"
          :edit-render="{ name: 'input', autoselect: true }"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template #default="{ row }">
            <span>{{ row.Entrusted_Collection_Amount | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="EntrustedTaxes" title="委托代开服务费/税费" width="200"
          :edit-render="{ name: 'input', autoselect: true }"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template #default="{ row }">
            <span>{{ row.EntrustedTaxes | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="EntrustedTotalAmount" title="委托代开运单总金额" width="200"
          :edit-render="{ name: 'input', autoselect: true }"
          v-if="$entrustSceneIds.includes(Number(getWaybillType.sceneId))">
          <template #default="{ row }">
            <span>{{ row.EntrustedTotalAmount | formatMoney }}</span>
          </template>
        </vxe-table-column>
        <vxe-table-column align="center" field="Unloading_net_weight" title="卸货净重(吨)" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <!-- <vxe-table-column align="center" field="RiskPath" title="风控路径" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
        <vxe-table-column align="center" field="Rrmark1" title="处理/驳回原因" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column> -->
        <vxe-table-column align="center" field="Rrmark" title="风控路径" width="200"></vxe-table-column>
        <vxe-table-column align="center" field="Rrmark1" title="处理/驳回原因" width="200"></vxe-table-column>
        <!-- <vxe-table-column align="center" field="" title="支付方式" width="200" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column>
        <vxe-table-column align="center" field="" title="开票金额" width="200" :edit-render="{name: 'input', autoselect: true}"></vxe-table-column> -->
        <vxe-table-column align="center" field="SourceBillId" title="溯源运单号" width="200"
          :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
      </vxe-table>
      <!-- 分页 -->
      <el-pagination background style="margin-top: 16px" @current-change="pagination6Change"
        :current-page.sync="pagination6.page" :page-size="pagination6.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination6.total">
      </el-pagination>
      <div style="display: flex; justify-content: center; align-items: center">
        <div style="margin-top: 16px">
          <el-button style="margin: 0 0 0 16px" type="primary" @click="saveWaybill">保存编辑</el-button>
          <el-button style="margin: 0 0 0 16px" type="primary" @click="revertWaybill">撤销编辑</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 修改备注dialog -->
    <el-dialog width="600px" :visible.sync="flag.updateRemarkDialog" append-to-body>
      <el-input type="textarea" autosize placeholder="请输入备注" maxlength="400" v-if="JSON.stringify() != '{}'"
        v-model="currentItem.Remark"></el-input>
      <div style="text-align: center; margin-top: 16px">
        <el-button style="margin-right: 16px" type="primary" size="mini" @click="updateRemark">确认</el-button>
        <el-button type="primary" size="mini" @click="flag.updateRemarkDialog = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 上传凭证dialog -->
    <el-dialog width="1200px" title="凭证" center :visible.sync="flag.voucherDialog" append-to-body
      v-if="flag.voucherDialog" :close-on-click-modal="false">
      <EvidenceInfo :businessID="voucherType == 'ImportBatchID'
        ? currentItem.ImportBatchID
        : currentWaybillItem.WaybillNumber || currentWaybillItem.WaybillID
        ">
      </EvidenceInfo>
    </el-dialog>

    <!-- 地图组件dialog -->
    <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="flag.showMap" :list="mapList" ref="map"></TXmap>
    </el-dialog>

    <!-- 车辆信息dialog -->
    <el-dialog width="1300px" :visible.sync="flag.showVehicleInfo" append-to-body>
      <VehicleInfo ref="vehicleInfo" v-if="flag.showVehicleInfo" :vehicleInfo="vehicleInfo"></VehicleInfo>
    </el-dialog>

    <!-- 操作员信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.showDriverInfo" append-to-body>
      <DriverInfo :driverInfo="driverInfo" />
    </el-dialog>

    <!-- 上传运单错误信息dialog -->
    <el-dialog :visible.sync="flag.uploadErrorDialog" title="错误说明" center append-to-body>
      <div style="height: 60vh; overflow-y: scroll">
        <el-table :data="uploadErrorList" :header-cell-class-name="cellClass"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column align="center" prop="Row" label="错误行" width="200" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="Content" label="错误信息" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 提交人工审核错误弹窗 -->
    <el-dialog :visible.sync="flag.reviewErrorDialog" title="错误说明" center append-to-body>
      <div style="height: 60vh; overflow-y: scroll">
        <el-table :data="reviewErrorList" :header-cell-class-name="cellClass"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column align="center" prop="WaybillID" label="运单号" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="ErrorDesc" label="错误信息" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  ImportTrace,
  importEvidence,
  GetImportTraceAuth,
  getProviderInfo,
  transformWaybillExcel,
  MergeTable,
} from "@/api/waybill/waybillManage/index";
import {
  downTemplate,
  deleteOneBills,
  sumAuditInfo,
  sumMoney,
  updateImportFileInfo,
  getBillList,
  gillsInfoDetails,
  riskVerify,
  billsInfoDetailsErrorStatus,
  submitManualReview,
  downtBills,
  deleteBills,
  onlineEditingUpdateBills,
  CreateNewOrderByErrors,
  Set30wErrorStatus,
  GetMergeList,
  GetImportFileCompute
} from "@/api/waybill/waybillManage/waybillRepository";
import {
  getMapPath,
  getCarInfo,
  carInfoByNum,
  getDriverDetails,
  GetSysValueAsync,
} from "@/api/common/common";
import { mapMutations, mapGetters } from "vuex";
import EvidenceInfo from "@/components/businessCmpt/evidenceInfo";
import TXmap from "@/components/commonCmpt/TXmap";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import { GetHtwlFacilitatorIDAsync } from "@/api/contractLogistics/contractWaybill.js";
export default {
  data() {
    return {
      flag: {
        reviewErrorDialog: false, // 提交人工审核错误弹窗
        addVouchersDialog: false, //添加凭证URL弹窗
        allWaybillDialog: false, //所有运单详情dialog
        rightWaybillDialog: false, //正确运单详情dialog
        errorWaybillDialog: false, //错误运单详情dialog
        auditWaybillDialog: false, //审核中运单详情dialog
        editWaybillDialog: false, //可编辑运单dialog
        updateRemarkDialog: false, //备注编辑dialog
        voucherDialog: false, //凭证dialog
        riskVerifying: false, //风控校验中
        riskProgress: 0, //风控进度
        showMap: false, //显示地图
        showVehicleInfo: false, //显示车辆信息
        showDriverInfo: false, //显示操作员信息
        uploadvouchering: false, //上传凭证中
        dialogLoading: false, //dialog弹窗是否是loading状态
        uploadErrorDialog: false, //上传运单产生的错误信息dialog
        importTraceAuth: false, // 导入轨迹权限
        mergeTableDialog: false
      },
      searchForm: {
        importBatchID: "", //待结算单号
      },
      searchDriverForm: {
        driverIdCard: "",
        driverPhone: "",
        driverName: "",
        CarriageBillID: "",
        WaybillID: "",
      },
      searchRightForm: {
        CarriageBillID: "",
        WaybillID: "",
      },
      searchErrorForm: {
        ErrorDesc: "",
        ErrorDescPre: "",
        CarriageBillID: "",
        WaybillID: "",
      },
      mergePagination: {
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
      },
      pagination1: {
        //运单库
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
      },
      pagination2: {
        //所有运单
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
      },
      pagination3: {
        //正确运单
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
      },
      pagination4: {
        //错误运单
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
      },
      pagination6: {
        //可编辑运单
        page: 1,
        pagesize: 10,
        total: 0,
        loading: false,
      },
      mergeTableList: [],
      uploadErrorList: [], //上传运单产生的错误信息
      tableList1: [], //表格数据
      allWaybillList: [], //所有运单
      currentAllWaybillSelectedList: [], //当前勾选的所有运单二维数组
      rightWaybillList: [], //正确的运单列表
      auditInfo: {}, //审核信息
      currentRightWaybillSelectedList: [], //当前勾选的正确运单二维数组
      currentRightWaybillSelectedCount: 0, //当前勾选的正确运单数量
      currentRightWaybillAmount: 0, //当前勾选的正确运单的工单费
      errorWaybillList: [], //错误的运单列表
      currentErrorWaybillSelectedList: [], //当前勾选的错误运单二维数组
      auditWaybillList: [], //审核中的运单列表
      editWaybillList: [], //在线编辑的运单
      searchErrorEditForm: {
        ErrorDesc: "",
        CarriageBillID: "",
        WaybillID: "",
        driverPhone: "",
        payeePhone: "",
        originAddress: "",
        arriveAddress: "",
      },
      currentEditWaybillSelectedList: [], //当前勾选的正确运单二维数组
      riskResultList: [], //风控结果运单列表
      currentItem: {}, //当前续传所操作的对象
      vehicleInfo: {}, //车辆信息
      driverInfo: {}, //操作员信息
      mapList: [], //轨迹列表
      riskTime: "", //过风控时间
      supplierSelected: "", //选中的服务商
      supplierList: [], // 服务商列表
      multipleSelection: [],
      reviewErrorList: [],
      voucherType: "", // 上传凭证类别
      maxNum: 0, //单次最大上传数
      currentWaybillItem: {},
    };
  },
  methods: {
    ...mapMutations(["setWaybillType"]),
    //查询服务商
    async getProviderInfo(ImportBatchID) {
      this.supplierSelected = "";
      this.supplierList = [];
      let params = {
        TaskSceneTypeID: this.getWaybillType.sceneId,
        ImportBatchID,
        invoiceTaskId: "",
      };

      // 如果是合同物流, 只能选择货主指定的服务商
      let supplerIDoBJ = await GetHtwlFacilitatorIDAsync(ImportBatchID);
      let { data } = supplerIDoBJ;

      await getProviderInfo(params).then(async (res) => {
        //如果已签约服务商有值
        if (res.supplier && res.supplier.length) {
          res.supplier.forEach((item) => {
            this.supplierList.push({
              label:
                item.EnterpriseFullName +
                `${data == item.UserID ? " 合同物流" : ""}`,
              value: item.UserID,
              Flag: item.Flag,
              Disabled: !data ? false : data == item.UserID ? false : true,
            });
          });
          this.supplierSelected = this.supplierList.find(
            (item) => item.value == data
          )
            ? data
            : this.supplierList[0].value;
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 合并运单
    mergeTable() {
      this.$confirm("确定要合并运单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "运单合并中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let ids = this.multipleSelection.map((it) => {
            return it.ImportBatchID;
          });
          MergeTable({
            importBatchIDs: ids,
          })
            .then((res) => {
              this.$message.success("合并成功");
              this.multipleSelection = [];
              this.getBillList();
              this.flag.mergeTableDialog = false
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //搜索
    search() {
      this.pagination1.page = 1;
      this.getBillList();
    },
    // 导入轨迹权限
    getImportTraceAuth() {
      GetImportTraceAuth().then((res) => {
        this.flag.importTraceAuth = res.data;
      });
    },
    //下载模板
    downTemplate(type) {
      if (type == 1) {
        window.open(
          "https://res.gts56.com/fzkj/bsc/TempLate/%E9%A3%8E%E6%8E%A7%E6%A0%87%E5%87%86%E8%AF%B4%E6%98%8E.pdf",
          "_blank"
        );
      } else {
        let params = {
          taskTypeId: this.waybillBusinessTypeId,
          taskSceneTypeID: this.getWaybillType.sceneId,
        };
        downTemplate(params).then((res) => {
          window.location.href = res.data;
        });
      }
    },
    //打开上传凭证弹窗
    openVoucherDialog(item, type) {
      if (type == "ImportBatchID") {
        this.currentItem = item;
      } else {
        this.currentWaybillItem = item;
      }
      this.voucherType = type;
      this.flag.voucherDialog = true;
    },
    getMergeList() {
      let params = {
        pageIndex: this.mergePagination.page,
        pageSize: this.mergePagination.pagesize,
        taskSceneTypeID: this.getWaybillType.sceneId
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      GetMergeList(params)
        .then((res) => {
          if (res.data.DataList) {
            this.mergeTableList = res.data.DataList.map((item, index) => {
              item.index = index + 1;
              return item;
            });
          } else {
            this.mergeTableList = [];
          }
          this.mergePagination.total = res.data.TotalCount;
          this.flag.mergeTableDialog = true
        })
        .finally(() => {
          loading.close();
        });
    },
    mergePaginationChange(e) {
      this.mergePagination.page = e;
      this.getMergeList();
    },
    mergePaginationSizeChange(e) {
      this.mergePagination.pagesize = e;
      this.getMergeList();
    },
    //分页回调
    pagination1Change(e) {
      this.pagination1.page = e;
      this.getBillList();
    },
    pagination1SizeChange(e) {
      this.pagination1.pagesize = e;
      this.getBillList();
    },
    //所有运单分页回调
    pagination2Change(e) {
      this.pagination2.page = e;
    },
    //正确分页回调
    pagination3Change(e) {
      this.pagination3.page = e;
    },
    //错误分页回调
    pagination4Change(e) {
      this.pagination4.page = e;
    },
    pagination2SizeChange(val) {
      this.pagination2.pagesize = val;
    },
    pagination3SizeChange(val) {
      this.pagination3.pagesize = val;
    },
    pagination4SizeChange(val) {
      this.pagination4.pagesize = val;
    },
    //在线编辑分页回调
    pagination6Change(e) {
      this.pagination6.page = e;
    },
    //上传运单
    handleUploadWaybill(e) {
      if (
        !this.getWaybillType.sceneId ||
        !this.getWaybillType.sceneType ||
        !this.getWaybillType.taskType ||
        !this.getWaybillType.rateType ||
        !this.getWaybillType.taskTypeId
      ) {
        this.$router.push("/waybill/waybillManage/businessChoose");
      }
      this.pagination1.loading = true;
      let formData = new FormData();
      formData.append("file", e.srcElement.files[0] || e.target.files[0]);
      formData.append("taskSceneType", this.getWaybillType.sceneId);
      transformWaybillExcel(formData)
        .then((res) => {
          //上传运单会使运单库增加一条记录  所以需要拿到最新的运单库
          this.getBillList();
        })
        .catch((e) => {
          if (!e.data.data) return;
          this.uploadErrorList = e.data.data;
          this.flag.uploadErrorDialog = true;
          this.getProviderInfo(this.currentItem.ImportBatchID);
        })
        .finally(() => {
          this.$refs.waybill.value = null;
          this.pagination1.loading = false;
        });
    },
    // 导入轨迹
    importTrace(e) {
      const loading = this.$loading({
        lock: true,
        text: "轨迹导入中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const file = e.srcElement.files[0] || e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      ImportTrace(formData)
        .then((res) => {
          this.getBillList();
        })
        .finally(() => {
          this.$refs.importTrace.value = null;
          loading.close();
        });
    },
    // 导入凭证
    importEvidence(e) {
      const loading = this.$loading({
        lock: true,
        text: "凭证导入中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const file = e.srcElement.files[0] || e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      importEvidence(formData)
        .then((res) => {
          this.getBillList();
        })
        .finally(() => {
          this.$refs.importEvidence.value = null;
          loading.close();
        });
    },
    //获得业务类型和发票费率
    getWaybillBusinessType() {
      if (
        !this.getWaybillType.sceneId ||
        !this.getWaybillType.sceneType ||
        !this.getWaybillType.taskType ||
        !this.getWaybillType.rateType ||
        !this.getWaybillType.taskTypeId
      ) {
        this.$router.push("/waybill/waybillManage/businessChoose");
      }
      this.waybillSceneId = this.getWaybillType.sceneId;
      this.waybillSceneType = this.getWaybillType.sceneType;
      this.waybillBusinessType = this.getWaybillType.taskType;
      this.waybillBusinessTypeId = this.getWaybillType.taskTypeId;
      this.waybillRate = this.getWaybillType.rateType;
    },
    //获取运单库列表
    getBillList() {
      if (!this.getWaybillType.sceneId) return;
      let params = {
        pageIndex: this.pagination1.page,
        pageSize: this.pagination1.pagesize,
        taskSceneTypeID: this.getWaybillType.sceneId,
        importBatchID: this.searchForm.importBatchID,
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      GetMergeList(params)
        .then((res) => {
          if (res.data.DataList) {
            this.tableList1 = res.data.DataList.map((item, index) => {
              GetImportFileCompute({
                importBatchID: item.ImportBatchID,
                taskSceneTypeID: item.TaskSceneTypeIdStr
              }).then(ress => {
                item.CorrectBillCount = ress.data.CorrectBillCount
                item.ErrorBillCount = ress.data.ErrorBillCount
                item.AuditBillCount = ress.data.AuditBillCount
                item.TotalAmount = ress.data.TotalAmount
                item.CorrectAmount = ress.data.CorrectAmount
              })
              item.index = index + 1;
              item.addToing = false; //续传的loading
              item.openFlowing = false; //风控中的loading
              return item;
            });
          } else {
            this.tableList1 = [];
          }
          this.pagination1.total = res.data.TotalCount;
        })
        .finally(() => {
          loading.close();
        });
    },
    //查看所有运单
    openAllWaybill(item) {
      if (item.WayBillCount == 0) {
        this.$message.warning("暂无运单");
        return;
      }
      this.currentItem = item;
      this.flag.allWaybillDialog = true;
      this.pagination2.loading = true;
      let params = {
        importBatchId: this.currentItem.ImportBatchID,
        // pageIndex: this.pagination2.page,
        pageIndex: 1,
        pageSize: 100000,
        statusList: [],
        driverIdCard: this.searchDriverForm.driverIdCard,
        driverPhone: this.searchDriverForm.driverPhone,
        driverName: this.searchDriverForm.driverName,
        CarriageBillID: this.searchDriverForm.CarriageBillID,
        WaybillID: this.searchDriverForm.WaybillID,
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      gillsInfoDetails(params)
        .then((res) => {
          this.allWaybillList = res.data.DataList;
          this.pagination2.total = res.data.TotalCount;
          //处理成二维数组
          let arrLength = parseInt(
            Number(res.data.TotalCount) / this.pagination2.pagesize + 1
          );
          for (let i = 0; i < arrLength; i++) {
            this.currentAllWaybillSelectedList[i] = [];
          }
        })
        .finally(() => {
          loading.close();
          this.pagination2.loading = false;
        });
    },
    //查看正确运单
    openRightWaybill(item) {
      if (item.CorrectBillCount == 0) {
        this.$message.warning("暂无运单");
        return;
      }
      this.currentItem = item;
      this.flag.rightWaybillDialog = true;
      this.pagination3.loading = true;
      let params = {
        importBatchId: this.currentItem.ImportBatchID,
        // pageIndex: this.pagination3.page,
        pageIndex: 1,
        pageSize: 100000,
        statusList: [1, 6],
        CarriageBillID: this.searchRightForm.CarriageBillID,
        WaybillID: this.searchRightForm.WaybillID,
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      gillsInfoDetails(params)
        .then((res) => {
          this.rightWaybillList = res.data.DataList;
          this.pagination3.total = res.data.TotalCount;
          this.riskTime = JSON.parse(res.data.Extend).RiskTime;
          //处理成二维数组
          let arrLength = parseInt(
            Number(res.data.TotalCount) / this.pagination3.pagesize + 1
          );
          for (let i = 0; i < arrLength; i++) {
            this.currentRightWaybillSelectedList[i] = [];
          }
        })
        .finally(() => {
          loading.close();
          this.pagination3.loading = false;
        });
    },
    //查看错误运单
    openErrorWaybill(item) {
      if (item) {
        if (item.ErrorBillCount == 0) {
          this.$message.warning("暂无运单");
          return;
        }
        this.currentItem = item;
        this.flag.errorWaybillDialog = true;
      }
      this.pagination4.loading = true;
      let params = {
        importBatchId: this.currentItem.ImportBatchID,
        // pageIndex: this.pagination4.page,
        pageIndex: 1,
        pageSize: 100000,
        statusList: [2, 4],
        ErrorDesc: this.searchErrorForm.ErrorDesc,
        ErrorDescPre: this.searchErrorForm.ErrorDescPre,
        CarriageBillID: this.searchErrorForm.CarriageBillID,
        WaybillID: this.searchErrorForm.WaybillID,
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      gillsInfoDetails(params)
        .then((res) => {
          this.errorWaybillList = res.data.DataList;
          this.pagination4.total = res.data.TotalCount;
          this.riskTime = JSON.parse(res.data.Extend).RiskTime;
          //处理成二维数组
          let arrLength = parseInt(
            Number(res.data.TotalCount) / this.pagination4.pagesize + 1
          );
          for (let i = 0; i < arrLength; i++) {
            this.currentErrorWaybillSelectedList[i] = [];
          }
          // 获取服务商
          this.getProviderInfo(this.currentItem.ImportBatchID);
        })
        .finally(() => {
          loading.close();
          this.pagination4.loading = false;
        });
    },
    //查看审核信息
    openAutioInfo(item) {
      this.currentItem = item;
      this.flag.auditWaybillDialog = true;
      let params = {
        importBatchId: this.currentItem.ImportBatchID,
      };
      sumAuditInfo(params).then((res) => {
        this.auditInfo = res.data;
      });
    },
    //打开在线编辑弹窗
    openEditWaybillDialog(type) {
      this.pagination6.loading = true;
      let params = {
        importBatchId: this.currentItem.ImportBatchID,
        pageIndex: 1,
        pageSize: 100000,
        statusList: [2, 4],
        ErrorDesc: this.searchErrorEditForm.ErrorDesc,
        CarriageBillID: this.searchErrorEditForm.CarriageBillID,
        WaybillID: this.searchErrorEditForm.WaybillID,
        driverPhone: this.searchErrorEditForm.driverPhone,
        payeePhone: this.searchErrorEditForm.payeePhone,
        originAddress: this.searchErrorEditForm.originAddress,
        arriveAddress: this.searchErrorEditForm.arriveAddress,
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      gillsInfoDetails(params)
        .then((res) => {
          if (res.data.DataList.length === 0 && type != "search") {
            this.$message.warning("暂无错误运单可以编辑");
            return;
          }
          this.flag.editWaybillDialog = true;
          this.editWaybillList = res.data.DataList;
          this.pagination6.total = res.data.TotalCount;
          //处理成二维数组
          let arrLength = parseInt(
            Number(res.data.TotalCount) / this.pagination6.pagesize + 1
          );
          for (let i = 0; i < arrLength; i++) {
            this.currentEditWaybillSelectedList[i] = [];
          }
        })
        .finally(() => {
          loading.close();
          this.pagination6.loading = false;
        });
    },
    //保存在线编辑的运单
    saveWaybill() {
      let params = {
        billId: this.editWaybillList.map((item) => {
          delete item._XID;
          return item;
        }),
      };
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      onlineEditingUpdateBills(params)
        .then((res) => {
          this.getBillList();
          this.flag.editWaybillDialog = false;
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
    //撤销编辑
    revertWaybill() {
      this.$refs.editWaybillRef.revertData();
    },
    // 勾选生成新单
    createNewOrder(item, type, pageType) {
      let list = [];
      if (pageType == "total") {
        list = JSON.parse(JSON.stringify(this.currentAllWaybillSelectedList));
      } else if (pageType == "right") {
        list = JSON.parse(JSON.stringify(this.currentRightWaybillSelectedList));
      } else {
        list = JSON.parse(JSON.stringify(this.currentErrorWaybillSelectedList));
      }
      let arr = [];
      if (type == "some") {
        list.forEach((it) => {
          it.forEach((el) => {
            arr.push(el);
          });
        });
        if (arr.length == 0) {
          this.$message.warning("尚未勾选运单");
          return;
        }
      } else {
        if (pageType == "total") {
          arr = JSON.parse(JSON.stringify(this.allWaybillList));
        } else if (pageType == "right") {
          arr = JSON.parse(JSON.stringify(this.rightWaybillList));
        } else {
          arr = JSON.parse(JSON.stringify(this.errorWaybillList));
        }
      }
      this.$confirm(
        type == "all" ? "确认全部生成新单吗？" : "确认勾选生成新单吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let params = {
            importBatchID: item.ImportBatchID,
            waybillIds: arr.map((i) => {
              return i.WaybillNumber;
            }),
          };
          const loading = this.$loading({
            lock: true,
            text: "请求中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          CreateNewOrderByErrors(params)
            .then((res) => {
              this.$message.success("操作成功");
              if (pageType == "total") {
                this.flag.allWaybillDialog = false;
              } else if (pageType == "right") {
                this.flag.rightWaybillDialog = false;
              } else {
                this.flag.errorWaybillDialog = false;
              }

              this.getBillList();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch();
    },

    //删除一个运单库  如果第二个参数不为空则删除该运单库的错误运单
    deleteOne(item, type) {
      this.$confirm("确认删除全部错误运单吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            importBatchId: item.ImportBatchID,
          };
          if (type) {
            params.status = type;
          } else {
            if (this.tableList1.length === 1) {
              this.pagination1.page = 1;
            }
          }
          const loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          deleteBills(params)
            .then((res) => {
              this.flag.errorWaybillDialog = false;
              this.getBillList();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch();
    },
    //删除多条错误运单
    deleteErrorWaybill() {
      this.$confirm("确认删除这些错误运单吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arr = [];
          this.currentErrorWaybillSelectedList.forEach((item) => {
            item.forEach((el) => {
              arr.push(el);
            });
          });
          if (arr.length == 0) {
            this.$message.warning("尚未勾选运单");
            return;
          }
          let params = {
            wayBillId: arr,
          };
          const loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          deleteOneBills(params)
            .then((res) => {
              //删除后刷新删除列表
              let params = {
                importBatchId: this.currentItem.ImportBatchID,
                pageIndex: this.pagination4.page,
                pageSize: 100000,
                statusList: [2, 4],
              };
              gillsInfoDetails(params).then((res) => {
                this.errorWaybillList = res.data.DataList;
                this.pagination4.total = res.data.TotalCount;
                this.riskTime = JSON.parse(res.data.Extend).RiskTime;
              });
              this.getBillList();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch();
    },
    //删除多条运单
    deleteAllWaybill() {
      this.$confirm("确认删除这些运单吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arr = [];
          this.currentAllWaybillSelectedList.forEach((item) => {
            item.forEach((el) => {
              arr.push(el);
            });
          });
          if (arr.length == 0) {
            this.$message.warning("尚未勾选运单");
            return;
          }
          let params = {
            wayBillId: arr,
          };
          const loading = this.$loading({
            lock: true,
            text: "删除中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          deleteOneBills(params)
            .then((res) => {
              //删除后刷新数据
              let params = {
                importBatchId: this.currentItem.ImportBatchID,
                pageIndex: this.pagination2.page,
                pageSize: 100000,
                statusList: [],
              };
              gillsInfoDetails(params).then((res) => {
                this.allWaybillList = res.data.DataList;
                this.pagination2.total = res.data.TotalCount;
              });
              this.getBillList();
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch();
    },
    //点击续传按钮
    handleAddToClick(item) {
      this.currentItem = item;
      this.$refs.addTo.click();
      this.$refs.addTo.value = null;
    },
    //续传
    handleAddToUpload(e) {
      this.currentItem.addToing = true;
      let formData = new FormData();
      formData.append("file", e.srcElement.files[0] || e.target.files[0]);
      // formData.append('taskTypeId', this.getWaybillType.taskTypeId)
      // formData.append('taskSceneTypeID', this.getWaybillType.sceneId)
      // formData.append('importBatchId', this.currentItem.ImportBatchID)
      // excelResume(formData).then(res => {
      formData.append("file", e.srcElement.files[0] || e.target.files[0]);
      formData.append("taskSceneType", this.getWaybillType.sceneId);
      formData.append("importBatchId", this.currentItem.ImportBatchID);
      transformWaybillExcel(formData)
        .then((res) => {
          //续传后需要更新运单库列表
          this.getBillList();
        })
        .finally(() => {
          this.currentItem.addToing = false;
          this.$refs.addTo.value = null;
        });
    },

    Set30wErrorStatusAsync() {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      Set30wErrorStatus({
        importBatchId: this.currentItem.ImportBatchID,
        sceneId: this.currentItem.TaskSceneTypeId,
      })
        .then((res) => {
          this.openErrorWaybill();
          this.search();
        })
        .finally(() => {
          loading.close();
        });
    },
    //提交风控校验
    submitRiskVerify(item, type) {
      this.currentItem = item;
      if (type == "all") {
        this.flag.errorWaybillDialog = false;
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        //获取需要过风控的运单
        billsInfoDetailsErrorStatus({
          importBatchId: this.currentItem.ImportBatchID,
        })
          .then((res) => {
            if (res.details.length === 0) {
              this.$message.warning("暂无运单");
              return;
            }
            this.riskVerify(res.details);
          })
          .finally(() => {
            loading.close();
          });
      } else {
        let arr = [];
        this.currentErrorWaybillSelectedList.forEach((it) => {
          it.forEach((el) => {
            arr.push(el);
          });
        });
        if (arr.length == 0) {
          this.$message.warning("尚未勾选运单");
          return;
        }
        this.$confirm("确认勾选提交风控吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.flag.errorWaybillDialog = false;
            this.riskVerify(arr);
          })
          .catch();
      }
    },
    clearErrorDialog() {
      this.$refs["searchErrorForm"].resetFields();
      for (var key in this.searchErrorForm) {
        this.searchErrorForm[key] = "";
      }
    },

    riskVerify(arr) {
      let that = this;
      this.flag.riskVerifying = true;
      this.flag.riskProgress = 0;
      const maxNum = 1000;
      let progress = 0; //已经风控校验了多少条
      let errorCount = 0; //有多少条错的
      const len = arr.length; // 请求总数量
      let sendCount = 0; // 已发送的请求数量
      let finishCount = 0; // 已完成的请求数量
      return new Promise((resolve, reject) => {
        while (sendCount < maxNum && sendCount < len) {
          next();
        }
        function next() {
          let current = sendCount++; // 当前发送的请求数量，后加一 保存当前请求url的位置
          // 递归出口
          if (finishCount >= len) {
            // 如果所有请求完成，则解决掉 Promise，终止递归
            resolve();
            return;
          }
          if (arr[current]) {
            let params = {
              waybillId: arr[current].WaybillNumber,
              sceneId: arr[current].TaskSceneTypeID,
            };
            riskVerify(params)
              .then((res) => { })
              .catch((res) => {
                errorCount = errorCount + 1;
              })
              .finally(() => {
                finishCount = finishCount + 1;
                if (current < len - 1) {
                  // 如果请求没有发送完，继续发送请求
                  next();
                }
                progress = progress + 1;
                that.flag.riskProgress = parseInt((progress / len) * 100);
                if (that.flag.riskProgress == 100) {
                  //风控校验完毕后需要刷新列表获取新数据
                  that.getBillList();
                  //获取错误运单
                  let params = {
                    importBatchId: that.currentItem.ImportBatchID,
                    pageIndex: 1,
                    pageSize: 100000,
                    statusList: [2, 4],
                    ErrorDesc: that.searchErrorForm.ErrorDesc,
                    ErrorDescPre: that.searchErrorForm.ErrorDescPre,
                  };
                  gillsInfoDetails(params)
                    .then((res) => {
                      that.errorWaybillList = res.data.DataList;
                      that.pagination4.total = res.data.TotalCount;
                      that.riskTime = JSON.parse(res.data.Extend).RiskTime;
                      //处理成二维数组
                      let arrLength = parseInt(
                        Number(res.data.TotalCount) /
                        that.pagination4.pagesize +
                        1
                      );
                      for (let i = 0; i < arrLength; i++) {
                        that.currentErrorWaybillSelectedList[i] = [];
                      }
                    })
                    .finally(() => {
                      //延迟一秒关闭风控界面
                      let timer = setTimeout(() => {
                        that.flag.riskVerifying = false;
                        // 一条错的都没有
                        if (that.pagination4.total === 0) {
                          that.$message.success("风控校验通过");
                        } else {
                          that.flag.errorWaybillDialog = true;
                          that.$message.warning(
                            "您有未通过风控的运单，请查看风控结果！"
                          );
                          that.getProviderInfo(that.currentItem.ImportBatchID);
                        }
                        clearTimeout(timer);
                      }, 1000);
                    });
                }
              });
          }
        }
      });
    },

    //提交人工审核
    submitManualReview() {
      if (!this.supplierSelected) {
        this.$message.warning("请选择服务商！");
        return;
      }
      this.$confirm("确认把这些错误运单提交人工审核吗", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arr = [];
          this.currentErrorWaybillSelectedList.forEach((item) => {
            item.forEach((el) => {
              arr.push(el);
            });
          });
          if (arr.length == 0) {
            this.$message.warning("尚未勾选运单");
            return;
          }
          let params = {
            providerID: this.supplierSelected,
            waybillIds: arr.map((item) => item.WaybillNumber),
          };
          const loading = this.$loading({
            lock: true,
            text: "请求中...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          submitManualReview(params)
            .then((res) => {
              this.currentErrorWaybillSelectedList = [];
              //刷新列表
              let params = {
                importBatchId: this.currentItem.ImportBatchID,
                pageIndex: this.pagination4.page,
                pageSize: 100000,
                statusList: [2, 4],
              };
              gillsInfoDetails(params).then((res) => {
                this.$message.success("提交成功");
                this.errorWaybillList = res.data.DataList;
                this.pagination4.total = res.data.TotalCount;
              });
              this.currentErrorWaybillSelectedList = [];
              // 获取服务商
              this.getProviderInfo(this.currentItem.ImportBatchID);
              this.getBillList();
            })
            .catch((res) => {
              this.flag.reviewErrorDialog = true;
              this.reviewErrorList = res.data.data
                ? JSON.parse(res.data.data)
                : [];
            })
            .finally(() => {
              loading.close();
            });
        })
        .catch(() => {
          this.$message.warning("已取消");
        });
    },
    //下载运单
    downloadWaybill(status) {
      let params = {
        importBatchId: this.currentItem.ImportBatchID,
      };
      if (status) {
        params.status = status;
      }
      const loading = this.$loading({
        lock: true,
        text: "下载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      downtBills(params)
        .then((res) => {
          location.href = res.down[0].pathName;
        })
        .finally(() => {
          loading.close();
        });
    },
    //开启流程
    openFlow(item) {
      this.currentItem = item;
      let params = {
        importBatchId: this.currentItem.ImportBatchID,
        pageIndex: 1,
        pageSize: 100000,
        statusList: [1, 6],
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      gillsInfoDetails(params)
        .then((res) => {
          if (res.data.DataList.length === 0) {
            this.$message.warning("暂无正确运单");
            return;
          }
          this.setWaybillType({
            waybillList: res.data.DataList.map((item, index) => {
              item.index = index + 1;
              return item;
            }),
            taskType: item.TaskTypeName,
            taskTypeId: item.TaskTypeId,
            rateType: item.InvoiceTypeName,
            sceneType: item.TaskScenceTypeName,
            sceneId: item.TaskSceneTypeId,
          });

          // 无服务商流程
          if (this.getWaybillType.sceneId == 12) {
            this.$router.push({
              path: "/waybill/waybillManage/noSupplierWaybillManage",
              query: { ImportBatchID: this.currentItem.ImportBatchID },
            });
          } else {
            this.$router.push({
              path: "/waybill/waybillManage/waybillManage",
              query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID },
            });
          }

          // if (this.getWaybillType.sceneId == 1) {
          //   this.$router.push({ path: '/waybill/waybillManage/index', query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID } })
          // } else if (this.getWaybillType.sceneId == 3) {
          //   this.$router.push({ path: '/waybill/waybillManage/rate3Transport', query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID } })
          // } else if (this.getWaybillType.sceneId == 6) {
          //   this.$router.push({ path: '/waybill/waybillManage/earthworkWaybill', query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID } })
          // } else if (this.getWaybillType.sceneId == 7) {
          //   this.$router.push({ path: '/waybill/waybillManage/buildworkWaybill', query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID } })
          // }
        })
        .finally(() => {
          loading.close();
        });
    },
    //选择一部分正确运单去看起流程
    chooseWaybillOpenFlow(item) {
      let arr = [];
      this.currentRightWaybillSelectedList.forEach((el) => {
        el.forEach((obj) => {
          arr.push(obj);
        });
      });
      if (arr.length === 0) {
        this.$message.warning("暂无正确运单");
        return;
      }
      this.setWaybillType({
        waybillList: arr.map((el, index) => {
          el.index = index + 1;
          return el;
        }),
        taskType: item.TaskTypeName,
        taskTypeId: item.TaskTypeId,
        rateType: item.InvoiceTypeName,
        sceneType: item.TaskScenceTypeName,
        sceneId: item.TaskSceneTypeId,
      });
      this.$router.push({
        path: "/waybill/waybillManage/waybillManage",
        query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID },
      });
      // if (this.getWaybillType.sceneId == 1) {
      //   this.$router.push({ path: '/waybill/waybillManage/index', query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID } })
      // } else if (this.getWaybillType.sceneId == 3) {
      //   this.$router.push({ path: '/waybill/waybillManage/rate3Transport', query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID } })
      // } else if (this.getWaybillType.sceneId == 6) {
      //   this.$router.push({ path: '/waybill/waybillManage/earthworkWaybill', query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID } })
      // } else if (this.getWaybillType.sceneId == 7) {
      //   this.$router.push({ path: '/waybill/waybillManage/buildworkWaybill', query: { step: 1, ImportBatchID: this.currentItem.ImportBatchID } })
      // }
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.dialogLoading = true;
      let params = {
        CarNumber: item.CarNumber || item.LicensePlate,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.showVehicleInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //打开地图
    openMap(item) {
      this.flag.dialogLoading = true;
      let params = {
        wayBillId: item.WaybillNumber,
        searchWord: item.CarNumber || item.LicensePlate,
        stDate: item.LoadingDatetime || item.Loading_time,
        edDate: item.SignDatetime || item.Submission_time,
        coordType: 2,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //查看操作员信息
    openDerverInfo(item, UserID, idcard) {
      this.flag.dialogLoading = true;
      let data = {};
      let querydata = { UserID: UserID, AscriptionUserID: item.UserID };
      if (UserID == 0) {
        querydata = { driverIdCard: idcard, AscriptionUserID: item.UserID };
      }
      getDriverDetails(querydata)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.flag.showDriverInfo = true;
        })
        .finally(() => {
          this.flag.dialogLoading = false;
        });
    },
    //打开修改备注弹窗
    openUpdateReamrk(item) {
      this.currentItem = JSON.parse(JSON.stringify(item));
      this.flag.updateRemarkDialog = true;
    },
    //修改备注
    updateRemark() {
      let params = {
        ImportBatchId: this.currentItem.ImportBatchID,
        Remark: this.currentItem.Remark,
      };
      updateImportFileInfo(params)
        .then((res) => {
          this.getBillList();
        })
        .finally(() => {
          this.flag.updateRemarkDialog = false;
        });
    },
    cellClassName({ row, rowIndex, column, columnIndex }) {
      if (column.property === "ErrorMsg") {
        return "vux-table-col-red";
      }
    },
    headerCellClassName({ column, columnIndex }) {
      if (column.property === "ErrorMsg") {
        return "vux-table-col-red";
      }
    },
    //这个方法是为了给表头加上这个类名  更改样式去掉全选功能
    cellClass(row) {
      if (row.columnIndex === 0) {
        return "DisableSelection";
      }
    },
    //处理正确的表格选中变化
    currentRightWaybillSelectedChange(e, row) {
      this.currentRightWaybillSelectedList[this.pagination3.page - 1] = e;
      let count = 0;
      let arr = [];
      //当前组合包发票总面额
      this.currentRightWaybillSelectedList.forEach((item) => {
        item.forEach((el) => {
          count = count + 1;
          arr = arr.concat(el);
        });
      });
      this.currentRightWaybillSelectedCount = count; //数量
      sumMoney({ wayBillId: arr }).then((res) => {
        this.currentRightWaybillAmount = res.money; //工单费
      });
    },
    //处理错误的表格选中变化
    currentErrorWaybillSelectedChange(e, row) {
      this.currentErrorWaybillSelectedList[this.pagination4.page - 1] = e;
    },
    //处理编辑的表格选中变化
    currentEditWaybillSelectedChange({ records }) {
      this.currentEditWaybillSelectedList[this.pagination6.page - 1] = records;
    },
    //处理所有的表格选中变化
    currentAllWaybillSelectedChange(e, row) {
      this.currentAllWaybillSelectedList[this.pagination2.page - 1] = e;
    },
  },
  created() {
    // 查询单次最大上传数量
    GetSysValueAsync({
      keyType: "WayBill",
      keyName: "ImportQuantityLimit",
    }).then((res) => {
      this.maxNum = res.data.KeyValue;
    });
    //获取业务类型
    this.getWaybillBusinessType();
    //获取运单库列表
    this.getBillList();
    // 获取导入轨迹权限
    this.getImportTraceAuth();
    document.title = `${this.getWaybillType.sceneType}_运服通`;
  },
  computed: {
    ...mapGetters(["getWaybillType", "getInvoiceTaskID"]),
    currentEditWaybillList() {
      //编辑数据
      this.$nextTick(() => {
        if (
          this.currentEditWaybillSelectedList.length != 0 &&
          this.currentEditWaybillSelectedList[this.pagination6.page - 1]
            .length != 0
        ) {
          this.currentEditWaybillSelectedList[
            this.pagination6.page - 1
          ].forEach((item) => {
            this.editWaybillList.forEach((el) => {
              if (el.WaybillNumber === item.WaybillNumber) {
                this.$refs.editWaybillRef.setCheckboxRow(el, true);
              }
            });
          });
        }
      });
      return this.editWaybillList.slice(
        this.pagination6.pagesize * (this.pagination6.page - 1),
        this.pagination6.pagesize * this.pagination6.page
      );
    },
    currentRightWaybillList() {
      //正确数据
      //选中表格
      this.$nextTick(() => {
        if (
          this.currentRightWaybillSelectedList.length != 0 &&
          this.currentRightWaybillSelectedList[this.pagination3.page - 1]
            .length != 0
        ) {
          this.currentRightWaybillSelectedList[
            this.pagination3.page - 1
          ].forEach((item) => {
            this.rightWaybillList.forEach((el) => {
              if (el.WaybillNumber === item.WaybillNumber) {
                this.$refs.rightWaybillRef.toggleRowSelection(el, true);
              }
            });
          });
        }
      });
      return this.rightWaybillList.slice(
        this.pagination3.pagesize * (this.pagination3.page - 1),
        this.pagination3.pagesize * this.pagination3.page
      );
    },
    currentErrorWaybillList() {
      //错误数据
      //选中表格
      this.$nextTick(() => {
        if (
          this.currentErrorWaybillSelectedList.length != 0 &&
          this.currentErrorWaybillSelectedList[this.pagination4.page - 1]
            .length != 0
        ) {
          this.currentErrorWaybillSelectedList[
            this.pagination4.page - 1
          ].forEach((item) => {
            this.errorWaybillList.forEach((el) => {
              if (el.WaybillNumber === item.WaybillNumber) {
                this.$refs.errorWaybillRef.toggleRowSelection(el, true);
              }
            });
          });
        }
      });
      return this.errorWaybillList.slice(
        this.pagination4.pagesize * (this.pagination4.page - 1),
        this.pagination4.pagesize * this.pagination4.page
      );
    },
    currentAllWaybillList() {
      //所有运单数据
      //选中表格
      this.$nextTick(() => {
        if (
          this.currentAllWaybillSelectedList.length != 0 &&
          this.currentAllWaybillSelectedList[this.pagination2.page - 1]
            .length != 0
        ) {
          this.currentAllWaybillSelectedList[this.pagination2.page - 1].forEach(
            (item) => {
              this.allWaybillList.forEach((el) => {
                if (el.WaybillNumber === item.WaybillNumber) {
                  this.$refs.allWaybillRef.toggleRowSelection(el, true);
                }
              });
            }
          );
        }
      });
      return this.allWaybillList.slice(
        this.pagination2.pagesize * (this.pagination2.page - 1),
        this.pagination2.pagesize * this.pagination2.page
      );
    },
  },
  watch: {
    "flag.allWaybillDialog"(val) {
      if (!val) {
        this.pagination2.page = 1;
      }
    },
    "flag.rightWaybillDialog"(val) {
      if (!val) {
        this.pagination3.page = 1;
        this.currentRightWaybillSelectedList = [];
        this.currentRightWaybillSelectedCount = 0;
        this.currentRightWaybillAmount = 0;
      }
    },
    "flag.errorWaybillDialog"(val) {
      if (!val) {
        this.pagination4.page = 1;
      }
    },
    "flag.editWaybillDialog"(val) {
      if (!val) {
        this.editWaybillList = [];
        //编辑完后刷新错误运单列表
        let timer = setTimeout(() => {
          this.openErrorWaybill(this.currentItem);
          clearTimeout(timer);
        }, 500);
      }
    },
  },
  components: {
    EvidenceInfo,
    TXmap,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss" scoped>
.waybillRepositoryPage {
  .riskVerify {
    height: calc(100vh - 150px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;

    .desc {
      font-size: 30px;
      font-weight: 700;
      margin-top: 30px;
    }
  }
}
</style>
<style>
.table-content .el-table {
  position: absolute;
  width: auto;
  max-width: none;
}

.vux-table-col-red {
  background: #f56c6c;
  color: #fff;
}

.vxe-table--tooltip-wrapper {
  z-index: 999999 !important;
}

.elx-table--tooltip-wrapper {
  z-index: 999999 !important;
}
</style>
